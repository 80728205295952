import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueSocketIO from 'vue-3-socket.io'
import SocketIO from "socket.io-client"

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/style.css'
import '@/assets/bootstrap.min.css'
//
window.api = 'https://shelp.brmja.com'


if (window.location.href.includes('/j/')) {

    console.log("g")

} else {

    if (window.location.search) {

        try {
            window.userdata = {
                name: decodeURI(window.location.search.split("?name=")[1].split("&")[0]),
                phone: decodeURI(window.location.search.split("phone=")[1].split("&")[0]),
                service: window.location.search.split("service=")[1].split("&")[0],
                user_id: window.location.search.split("id=")[1],
            }
            localStorage.setItem(
                "last",
                `/?name=${encodeURI(window.userdata.name)}&phone=${encodeURI(
                    window.userdata.phone
                )}&service=${window.userdata.service}&id=${window.userdata.user_id}`
            );
        } catch {
            if (window.location.pathname != "/confirm") {
                if (localStorage.getItem('last')) {
                    window.location = localStorage.getItem('last');
                } else {
                    window.location = '/confirm';
                }
            }
        }

    } else {

        if (window.location.pathname != "/confirm") {

            if (localStorage.getItem('last')) {
                window.location = localStorage.getItem('last');
            } else {
                window.location = '/confirm';
            }

        }

    }

}

if (window.userdata) {
    createApp(App).use(router).use(new VueSocketIO({
        debug: false,
        connection: SocketIO(window.api, {
            auth: {
                user: window.userdata,
                type: "user"
            }
        })
    })).mount('#app')
} else {
    createApp(App).use(router).mount('#app')
}