<template>
  <div class="home col-12 col-lg-6 c">
    <div class="header">
      <h1>الدعم الفني</h1>
      <h4 class="text-white">
        يمكنك التواصل معنا لأي مشكلة او استفسار او اقتراح.
      </h4>
    </div>
    <!-----start-main---->
    <!---728x90--->

    <div class="login-form">
      <div class="col-12 col-lg-8 c">
        <br />
        <div v-if="service == 'brmja'">
          <img
            :src="require('@/assets/logo.png')"
            style="width: 30px; height: 30px"
            alt=""
          />
          برمجة دوت كوم
        </div>
        <div v-if="service == 'tahdir'">
          <img
            :src="require('@/assets/tahdir.png')"
            style="width: 30px; height: 30px"
            alt=""
          />
          التحضير الذكي
        </div>
        <div v-if="service == 'naqar'">
          <img
            :src="require('@/assets/naqar.png')"
            style="width: 30px; height: 30px"
            alt=""
          />
          نقار - المساعد الذكي
        </div>
        <div v-if="service == 'whatsapp'">
          <img
            :src="require('@/assets/whatsapp.png')"
            style="width: 30px; height: 30px"
            alt=""
          />
          الواتس الذكي
        </div>
        <br />
        <button
          class="btn btn-lg btn-success btn-block"
          @click="$router.push('/call')"
        >
          <i class="fa fa-phone"></i>
          إتصال صوتي (مجاناً)
        </button>
        <br /><br />
        <button
          class="btn btn-lg btn-secondary btn-block"
          @click="$router.push('/chat')"
        >
          <i class="fa fa-comment"></i>
          المحادثة بالرسائل
        </button>
        <br /><br />
      </div>
    </div>
    <!---728x90--->
    <!--//End-login-form-->
    <!-----start-copyright---->
    <div class="copy-right">
      <!---728x90--->
      <p>جميع الحقوق محفوظة لدى شركة برمجة دوت كوم</p>
    </div>
    <!-----//end-copyright---->
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      api: window.api,
      service: window.userdata.service,
    };
  },
  created() {
    if (window.location.href.includes("jointo")) {
      this.$router.push("/" + window.location.href.split("jointo=")[1]);
    }
  },
};
</script>
