import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/call',
    name: 'call',
    component: () => import('../views/Call.vue')
  },
  {
    path: '/j/:f/:v',
    name: 'join',
    component: () => import('../views/Join.vue')
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('../views/Chat.vue')
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: () => import('../views/confirm.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
